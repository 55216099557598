import type { ComponentType } from "react"

export function withFloatingAndRotationEffect(Component): ComponentType {
    return (props) => {
        const intensity = props.intensity || "medium" // Default to medium if not specified
        const includeRotation = props.includeRotation || false // Default to false if not specified
        const animations = {
            low: {
                translateY: [-2, 2, -2],
                rotate: includeRotation ? [0, 2, -2, 0] : 0,
                transition: {
                    duration: 0.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                },
            },
            medium: {
                translateY: [-5, 5, -5],
                rotate: includeRotation ? [0, 3, -3, 0] : 0,
                transition: {
                    duration: 2.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                },
            },
            high: {
                translateY: [-10, 10, -10],
                rotate: includeRotation ? [0, 5, -5, 0] : 0,
                transition: {
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                },
            },
        }

        return <Component {...props} animate={animations[intensity]} />
    }
}
